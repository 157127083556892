import React, { useState } from 'react';
import axios from 'axios';
import '../assets/css/TrackGen.css';

const TrackGen = () => {
  const [formData, setFormData] = useState({ name: '', latitude: '', longitude: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const latitude = parseFloat(formData.latitude).toFixed(7);
    const longitude = parseFloat(formData.longitude).toFixed(7);

    const updatedData = {
      ...formData,
      latitude,
      longitude,
    };

    try {
      const response = await axios.post('https://www.lankamed.icu/api/save-location', updatedData);

      if (response.status === 200) {
        alert('Location data submitted successfully');
        setFormData({ name: '', latitude: '', longitude: '' });
      } else {
        alert('Error submitting location data');
      }
    } catch (error) {
      console.error('Error submitting location data:', error);
      alert('Failed to submit location data');
    }
  };

  return (
    <div className="track-gen-container">
      <h2>Submit Geo-Location Data</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="latitude">Latitude (up to 7 decimal places)</label>
          <input
            type="text"
            id="latitude"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="longitude">Longitude (up to 7 decimal places)</label>
          <input
            type="text"
            id="longitude"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn">Submit</button>
      </form>
    </div>
  );
};

export default TrackGen;