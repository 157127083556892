import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/Login.css';
import axios from 'axios';
import { UserContext } from './UserContext'; // Import UserContext

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '', latitude: null, longitude: null });
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState(''); // Store the username
  const [faceRecognitionLabel, setFaceRecognitionLabel] = useState(null);
  const [isApproved, setIsApproved] = useState(false);  // State to track approval
  const { setUser } = useContext(UserContext); // Access setUser from UserContext
  const navigate = useNavigate();
  const videoRef = useRef(null);

  // Function to check proximity of current location to saved locations
  const checkProximity = async () => {
    try {
      const locationCheckResponse = await axios.post('https://www.lankamed.icu/api/check-login-location', {
        latitude: formData.latitude,
        longitude: formData.longitude,
      });

      if (locationCheckResponse.data.autoLogin) {
        console.log('Auto-login due to proximity to saved location:', locationCheckResponse.data.location);

        // Auto-login the user without approval
        const loginResponse = await axios.post('https://www.lankamed.icu/api/login', formData);
        const userData = {
          id: loginResponse.data._id,
          name: loginResponse.data.name,
          email: loginResponse.data.email,
          role: loginResponse.data.role,
        };

        setUser(userData); // Set user data in context
        navigate('/home'); // Redirect to home page
      } else {
        console.log('No auto-login. Proceeding with normal login and approval process.');
        handleLogin();
      }
    } catch (error) {
      console.error('Error checking location proximity:', error);
      alert('Failed to check location proximity');
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle login
  const handleLogin = async () => {
    try {
      const response = await axios.post('https://www.lankamed.icu/api/login', formData);
      console.log('Login successful:', response.data);
      const userData = {
        id: response.data._id,
        name: response.data.name,
        email: response.data.email,
        role: response.data.role,
      };

      setUser(userData); // Set user data in context
      setUserName(response.data.name); // Store the username
      setIsLoading(true);

      if (response.data._id) {
        // Start polling for approval status
        const approvalInterval = setInterval(() => {
          console.log(`Checking approval status for userId: ${response.data._id}`);
          checkApprovalStatus(response.data._id);
        }, 2000);

        return () => clearInterval(approvalInterval);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkProximity(); // Check if the user is within 100 meters of any saved location
  };

  // Check approval status
  const checkApprovalStatus = async (id) => {
    try {
      const response = await axios.get(`https://www.lankamed.icu/api/track-logins/?userId=${id}`);

      if (response.data.length > 0 && response.data[0].approve) {
        console.log("User already approved.");
        setIsApproved(true);
        setIsLoading(false);
        navigate('/home'); // Redirect to home page
      }
    } catch (error) {
      console.error('Error checking approval status:', error);
    }
  };

  useEffect(() => {
    let interval;

    if (isLoading) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        })
        .catch((err) => {
          console.error("Error accessing webcam: ", err);
        });

      interval = setInterval(() => {
        if (videoRef.current) {
          const canvas = document.createElement('canvas');
          canvas.width = videoRef.current.videoWidth;
          canvas.height = videoRef.current.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.append('frame', blob, 'frame.jpg');

            fetch('http://127.0.0.1:5000/recognize', {
              method: 'POST',
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                setFaceRecognitionLabel(data.result);
                if (data.result === userName) {
                  console.log("Face recognition matched the username.");
                  navigate('/home'); // Redirect to home page
                } else {
                  console.log("Face does not match. Continuing approval check...");
                }
              })
              .catch((err) => {
                console.error('Face recognition fetch error:', err);
              });
          });
        }
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [isLoading, userName]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }));
      });
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-container">
        {isLoading ? (
          <div>
            <h2>Waiting for approval...</h2>
            <p>Please wait while your login is being approved by the admin.</p>
            <video ref={videoRef} style={{ width: '100%', height: 'auto' }}></video>
            {faceRecognitionLabel && <p>Recognized as: {faceRecognitionLabel}</p>}
          </div>
        ) : (
          <div>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn">Login</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;