import React, { useState, useEffect } from 'react';
import '../assets/css/Register.css';
import axios from 'axios';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'Admin',
    geoRange: 'Hospital',
    additionalInfo: '',
    userRecognitionName: '',
  });
  
  const [keystrokeData, setKeystrokeData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [wpm, setWpm] = useState(null);
  
  const predefinedText = "This is a test sentence to calculate your typing speed in words per minute.";

  useEffect(() => {
    if (formData.additionalInfo === predefinedText) {
      setEndTime(new Date().getTime());
    }
  }, [formData.additionalInfo, startTime]);

  useEffect(() => {
    if (startTime && endTime) {
      const timeTaken = (endTime - startTime) / 1000;
      const wordsTyped = predefinedText.split(' ').length;
      const calculatedWpm = (wordsTyped / timeTaken) * 60;
      console.log("Calculated WPM: ", Math.round(calculatedWpm));
      setWpm(Math.round(calculatedWpm));
    }
  }, [endTime]);

  const handleKeyDown = (e, fieldName) => {
    if (!startTime) {
      setStartTime(new Date().getTime());
    }
    const timestamp = new Date().getTime();
    setKeystrokeData(prevData => [
      ...prevData,
      { field: fieldName, key: e.key, eventType: 'keydown', timestamp }
    ]);
  };

  const handleKeyUp = (e, fieldName) => {
    const timestamp = new Date().getTime();
    setKeystrokeData(prevData => [
      ...prevData,
      { field: fieldName, key: e.key, eventType: 'keyup', timestamp }
    ]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = { ...formData, keystrokeData, wpm };
      console.log("Submitting data: ", dataToSubmit);
      await axios.post('https://www.lankamed.icu/api/register', dataToSubmit);
      alert('User registered successfully');
    } catch (error) {
      console.error('Error registering user', error);
      alert('Error registering user');
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e, 'name')}
            onKeyUp={(e) => handleKeyUp(e, 'name')}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userRecognitionName">User Recognition Name</label> {/* New field */}
          <input
            type="text"
            id="userRecognitionName"
            name="userRecognitionName"
            value={formData.userRecognitionName}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e, 'userRecognitionName')}
            onKeyUp={(e) => handleKeyUp(e, 'userRecognitionName')}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e, 'email')}
            onKeyUp={(e) => handleKeyUp(e, 'email')}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e, 'password')}
            onKeyUp={(e) => handleKeyUp(e, 'password')}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="Admin">Admin</option>
            <option value="Doctor">Doctor</option>
            <option value="Tracking Manager">Tracking Manager</option>
            <option value="Nurse">Nurse</option>
            <option value="OPD">OPD</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="geoRange">Geo Location Area Range</label>
          <select
            id="geoRange"
            name="geoRange"
            value={formData.geoRange}
            onChange={handleChange}
            required
          >
            <option value="Hospital">Hospital</option>
            <option value="Local">Local</option>
            <option value="International">International</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="additionalInfo">Type the following text to calculate your WPM:</label>
          <label htmlFor="additionalInfo"><b>This is a test sentence to calculate your typing speed in words per minute.</b></label>
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            placeholder={predefinedText}
            value={formData.additionalInfo}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyDown(e, 'additionalInfo')}
            onKeyUp={(e) => handleKeyUp(e, 'additionalInfo')}
            rows="4"
            required
          />
        </div>
        {wpm && (
          <div className="form-group">
            <p>Words Per Minute (WPM): {wpm}</p>
          </div>
        )}
        <button type="submit" className="btn">Register</button>
      </form>
    </div>
  );
};

export default Register;