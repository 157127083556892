import React, { useState, useEffect, useRef } from 'react';

function FaceRecognition() {
    const [result, setResult] = useState('No person detected');
    const [error, setError] = useState(null);
    const videoRef = useRef(null);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch(err => {
                console.error("Error accessing webcam: ", err);
                setError("Failed to access webcam");
            });

        const interval = setInterval(() => {
            if (videoRef.current) {
                const canvas = document.createElement('canvas');
                canvas.width = videoRef.current.videoWidth;
                canvas.height = videoRef.current.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(blob => {
                    const formData = new FormData();
                    formData.append('frame', blob, 'frame.jpg');

                    fetch('/recognize', {
                        method: 'POST',
                        body: formData,
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Invalid response from server');
                            }
                            return response.json();
                        })
                        .then(data => setResult(data.result))
                        .catch(err => {
                            console.error('Fetch error:', err);
                            setError('Failed to fetch data');
                        });
                });
            }
        }, 200);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h2>Face Recognition Result</h2>
            <video ref={videoRef} style={{ width: '100%', height: 'auto' }}></video>
            {error ? <label>{error}</label> : <label>{result}</label>}
        </div>
    );
}

export default FaceRecognition;