import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/styles.css';
import App from './App';
import { UserProvider } from './components/UserContext'; // Removed the trailing slash

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <UserProvider>
    <App />
  </UserProvider>
);