import React, { useState } from 'react';
import '../assets/css/RegisterPatient.css';

const RegisterPatient = () => {
  const [formData, setFormData] = useState({
    name: '',
    nic: '',
    dob: '',
    gender: '',
    guardianName: '',
    relationship: '',
    address: '',
    contactNumber: '',
    email: '',
    occupation: '',
    insurance: '',
    referringDoctor: '',
    emergencyContactName: '',
    emergencyRelationship: '',
    emergencyContactNumber: ''
  });

  const [isLocked, setIsLocked] = useState(false);
  const [password, setPassword] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
       ...prevFormData,
       [name]: value,
    }));
 };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    if (isLocked) {
      alert('System is locked. Enter the correct password.');
      return;
    }

    const isAnyFieldEmpty = Object.values(formData).some(field => field === '' || field === null || field === undefined);

    if (isAnyFieldEmpty) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      // Send the data to the Python encryption service
      const encryptedResponse = await fetch('https://www.lankamed.icu:5002/encrypt-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const encryptedData = await encryptedResponse.json();

      // Check for errors from the encryption API
      if (encryptedResponse.status !== 200) {
        throw new Error(encryptedData.error || 'Error encrypting data.');
      }

      // Send the encrypted data to the backend for saving in the database
      const response = await fetch('https://www.lankamed.icu/api/register-patient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(encryptedData)
      });

      if (response.ok) {
        alert('Patient registered successfully!');
      } else {
        alert('Error registering patient.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
};
  return (
    <div className="register-patient-container">
      <h2>Register Patient</h2>
      {isLocked ? (
        <div className="lock-screen">
          <h3>System Locked</h3>
          <p>Please enter the password to continue.</p>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button onClick={() => setIsLocked(false)}>Unlock</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="nic">NIC</label>
              <input
                type="text"
                id="nic"
                name="nic"
                value={formData.nic}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="guardianName">Guardian Name</label>
              <input
                type="text"
                id="guardianName"
                name="guardianName"
                value={formData.guardianName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="relationship">Relationship</label>
              <input
                type="text"
                id="relationship"
                name="relationship"
                value={formData.relationship}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="contactNumber">Contact Number</label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="occupation">Occupation</label>
              <input
                type="text"
                id="occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="insurance">Health Insurance</label>
              <input
                type="text"
                id="insurance"
                name="insurance"
                value={formData.insurance}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="referringDoctor">Referring Doctor</label>
              <input
                type="text"
                id="referringDoctor"
                name="referringDoctor"
                value={formData.referringDoctor}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="emergencyContactName">Emergency Contact Name</label>
              <input
                type="text"
                id="emergencyContactName"
                name="emergencyContactName"
                value={formData.emergencyContactName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="emergencyRelationship">Emergency Contact Relationship</label>
              <input
                type="text"
                id="emergencyRelationship"
                name="emergencyRelationship"
                value={formData.emergencyRelationship}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="emergencyContactNumber">Emergency Contact Number</label>
              <input
                type="tel"
                id="emergencyContactNumber"
                name="emergencyContactNumber"
                value={formData.emergencyContactNumber}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="btn">Register</button>
        </form>
      )}
    </div>
  );
};

export default RegisterPatient;