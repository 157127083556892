import React, { useState, useEffect } from 'react';
import '../assets/css/PatientDetails.css';
import axios from 'axios';

const PatientDetails = ({ patientId }) => {
  const [patientData, setPatientData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if patientId is passed and not undefined
    if (!patientId) {
      setError('Patient ID is missing');
      return;
    }

    // Fetch patient data
    const fetchPatientData = async () => {
      try {
        const response = await axios.get(`https://www.lankamed.icu/api/patient/${patientId}`);
        if (response.status === 200) {
          const { data } = response;

          // Decrypt NIC
          const decryptedNic = await decryptNic(data.encryptedNic); // Assuming you have a function to decrypt NIC

          // Store patient data
          setPatientData({ ...data, decryptedNic });
        } else {
          setError('Error fetching patient data');
        }
      } catch (err) {
        setError(`Error fetching or decrypting patient data: ${err.message}`);
        console.error('Error fetching or decrypting patient data:', err);
      }
    };

    fetchPatientData();
  }, [patientId]);

  // Decrypt NIC function (using your decrypt method)
  const decryptNic = async (encryptedNic) => {
    try {
      const response = await axios.post('https://www.lankamed.icu/decrypt-data', { encryptedNic });
      return response.data.decryptedNic;
    } catch (error) {
      console.error('Error decrypting NIC:', error);
      throw new Error('Error decrypting NIC');
    }
  };

  if (error) return <div>Error: {error}</div>;

  return patientData ? (
    <div className="patient-details-container">
      <h2>Patient Details</h2>
      <div className="table-wrapper">
        <table className="patient-details-table">
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>Name</th>
              <th>Encrypted NIC</th>
              <th>Decrypted NIC</th>
              <th>Date of Birth</th>
              <th>Gender</th>
              <th>Address</th>
              <th>Contact Number</th>
              <th>Email</th>
              <th>Guardian Name</th>
              <th>Relationship</th>
              <th>Occupation</th>
              <th>Insurance</th>
              <th>Referring Doctor</th>
              <th>Emergency Contact Name</th>
              <th>Emergency Contact Relationship</th>
              <th>Emergency Contact Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patientData.id}</td>
              <td>{patientData.name}</td>
              <td>{patientData.encryptedNic}</td>
              <td>{patientData.decryptedNic}</td>
              <td>{patientData.dateOfBirth}</td>
              <td>{patientData.gender}</td>
              <td>{patientData.address}</td>
              <td>{patientData.contactNumber}</td>
              <td>{patientData.email}</td>
              <td>{patientData.guardianName}</td>
              <td>{patientData.relationship}</td>
              <td>{patientData.occupation}</td>
              <td>{patientData.insurance}</td>
              <td>{patientData.referringDoctor}</td>
              <td>{patientData.emergencyContact.name}</td>
              <td>{patientData.emergencyContact.relationship}</td>
              <td>{patientData.emergencyContact.contactNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div>Loading patient data...</div>
  );
};

export default PatientDetails;